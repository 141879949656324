import React from 'react'
import ReactDOM from 'react-dom'
import is from 'is_js'

import { ARLink } from '@r2u/react-ar-components'
import state from '../state'
import product from '../services/product'
import { unique_impression, ar_link } from '../services/analytics'

interface Params {
  sku: string;
  resize?: boolean;
}

export default async function openAR ({ sku, resize = false }: Params): Promise<void> {
  if (!state.init) return
  if (is.desktop()) return

  const root = document.createElement('div')
  document.body.appendChild(root)

  const { glb, usdz } = await product(sku)

  unique_impression(sku)

  ReactDOM.render(
    <ARLink
      usdz={usdz}
      glb={glb}
      resize={resize}
      /* eslint-disable @typescript-eslint/camelcase */
      componentDidMount={ar_link.componentDidMount}
      onClick={ar_link.onClick}
      // eslint-enable @typescript-eslint/camelcase */
    />,
    root
  )
  ;(root.firstElementChild as HTMLElement).click()
}
