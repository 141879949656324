import state from '../state'
import product from '../services/product'
import { is_active_3d } from '../services/analytics'

export default async function isActive (sku: string): Promise<boolean> {
  if (!state.init) return Promise.resolve(false)

  const { isActive } = await product(sku)

  is_active_3d(isActive)

  return isActive
}
