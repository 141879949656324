import analytics from '../services/analytics'
import state, { Customer } from '../state'

interface Params {
  customerId: string;
}

export default async function init ({ customerId }: Params): Promise<void> {
  const ObjectIdRegex = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i
  if (!ObjectIdRegex.test(customerId)) {
    throw new Error(`Invalid customer '${customerId}'`)
  }

  const customer: Customer = {
    _id: customerId
  }
  state.init = true
  state.customer = customer
  state.analytics = analytics(customer._id)

  return Promise.resolve()
}
