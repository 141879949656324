import '@google/model-viewer/dist/model-viewer'
import init from './methods/init'
import isActive from './methods/isActive'
import openAR from './methods/openAR'
import create3DViewer from './methods/create3DViewer'
import getOpenARLink from './methods/getOpenARLink'
import analytics from './methods/analytics'
import customizer from './methods/customizer'

interface R2U {
  init: (params: { customerId: string }) => Promise<void>;
  isActive: (sku: string) => Promise<boolean>;
  openAR: (params: { sku: string; resize?: boolean }) => Promise<void>;
  getOpenARLink: (sku: string) => Promise<string>;
  create3DViewer: (params: {
    element: HTMLElement;
    sku: string;
    name: string;
    popup?: boolean;
    progressBarPosition?: 'top' | 'middle' | 'bottom';
    poster?: string | null;
  }) => Promise<void>;
  analytics: {
    send: (event: Record<string, string | number>) => Promise<void>;
  };
  customizer: {
    open: () => Promise<void>;
  };
}

declare global {
  interface Window {
    R2U: R2U;
  }
}

const R2U: R2U = {
  init,
  isActive,
  openAR,
  create3DViewer,
  getOpenARLink,
  analytics,
  customizer
}

window.R2U = R2U

export default R2U
