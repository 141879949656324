/* eslint-disable @typescript-eslint/camelcase */
import Analytics, { dimensions, metrics } from '@real2u/analytics'
import state from '../state'

const placements = {
  ar_link: 'ar_link',
  ar_model_viewer: 'ar_model_viewer'
}

const ar_link = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidMount: (): Promise<any> => {
    return state.analytics.send({ [metrics.impression_3d]: 1, [dimensions.placement]: placements.ar_link })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (): Promise<any> => {
    return state.analytics.send({ [metrics.click_3d]: 1, [dimensions.placement]: placements.ar_link })
  }
}

const ar_model_viewer = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidMount: (): Promise<any> => {
    return state.analytics.send({ [metrics.impression_3d]: 1, [dimensions.placement]: placements.ar_model_viewer })
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (): Promise<any> => {
    return state.analytics.send({ [metrics.click_3d]: 1, [dimensions.placement]: placements.ar_model_viewer })
  }
}

const unique_impression = (sku: string): void => {
  state.analytics.set(dimensions.sku, sku)
  state.analytics.send({ [metrics.unique_impression]: 1 }, { unique: metrics.unique_impression })
}

const is_active_3d = (isActive: boolean): void => {
  state.analytics.set(dimensions.is_active_3d, `${isActive}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (client: string): any {
  const params = {
    cognito: {
      identityPoolId: 'us-east-1:420f0cab-5285-4bf9-a8dd-7f2a213f3d28'
    },
    kinesis: {
      streamName: 'Real2UAnalyticsStream'
    },
    debug: !!(new URLSearchParams(window.location.search)).get('realidade-aumentada-debug')
  }

  return new Analytics(params, {
    [dimensions.client]: client
  })
}

export {
  ar_link,
  ar_model_viewer,
  unique_impression,
  is_active_3d
}
