import React from 'react'
import ReactDOM from 'react-dom'
import Customizer from '@r2u/customizer-react'
import state from '../../state'

export default async function open (): Promise<void> {
  const element = document.createElement('div')
  document.body.appendChild(element)

  ReactDOM.render(
    <React.StrictMode>
      <Customizer customerId={state.customer._id}/>
    </React.StrictMode>,
    element
  )

  Promise.resolve()
}
