import React from 'react'
import ReactDOM from 'react-dom'

import { ModelViewer } from '@r2u/react-ar-components'
import state from '../state'
import product from '../services/product'
import { unique_impression, ar_model_viewer } from '../services/analytics'

interface Params {
  element: HTMLElement;
  sku: string;
  name: string;
  popup?: boolean;
  progressBarPosition?: 'top' | 'middle' | 'bottom';
  progressBarColor?: string;
  poster?: string | null;
}

export default async function create3DViewer ({
  element,
  sku,
  name,
  popup = true,
  progressBarPosition = 'top',
  progressBarColor,
  poster = null
}: Params): Promise<void> {
  if (!state.init) return

  const { glb } = await product(sku)

  unique_impression(sku)

  ReactDOM.render(
    <ModelViewer
      glb={glb}
      name={name}
      popup={popup}
      /* eslint-disable @typescript-eslint/camelcase */
      componentDidMount={ar_model_viewer.componentDidMount}
      onClick={ar_model_viewer.onClick}
      /* eslint-enable @typescript-eslint/camelcase */
      progressBarPosition={progressBarPosition}
      progressBarColor={progressBarColor}
      poster={poster}
    />,
    element
  )
}
