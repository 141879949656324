/* eslint-disable @typescript-eslint/no-unused-vars */
import { Product } from './services/product'

export interface Customer {
  _id: string;
}

interface State {
  init: boolean;
  customer: Customer;
  analytics: {
    set(_key: string, _value: string): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    send(_data: any, _options?: any): Promise<void>;
  };
  products: Record<string, Product>;
}

const state: State = {
  init: false,
  customer: { _id: '' },
  analytics: {
    set: (_key: string, _value: string): void => undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    send: (_event: any): Promise<void> => Promise.resolve()
  },
  products: {}
}

export default state
