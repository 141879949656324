import { get } from './api'
import state from '../state'
import querystring from 'querystring'

interface CustomerProduct {
  product_reviews: Array<ProductReview>;
  assets: Array<Asset>;
}

interface Asset {
  type: string;
  url: string;
}

interface ProductReview {
  status: string;
}

export interface Product {
  glb: string;
  usdz: string;
  isActive: boolean;
}

export default async function (sku: string): Promise<Product> {
  if (state.products[sku]) return Promise.resolve(state.products[sku])

  const query = querystring.encode({
    'product.sku': sku,
    'asset.types': ['GLB', 'USDZ'].join(','),
    'product_review.status': 'RUNNING'
  })
  const [customerProduct] = await get(`/customers/${state.customer._id}/products/assets?${query}`) as Array<CustomerProduct>
  const assets = customerProduct.assets || []
  const productReviews = customerProduct.product_reviews || []
  const glbAsset = assets.find((asset: Asset) => asset.type === 'GLB')
  const usdzAsset = assets.find((asset: Asset) => asset.type === 'USDZ')

  const product: Product = {
    glb: glbAsset ? glbAsset.url : '',
    usdz: usdzAsset ? usdzAsset.url : '',
    isActive: productReviews.length > 0
  }

  state.products[sku] = product

  return product
}
