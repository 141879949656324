import { get } from '../services/api'
import state from '../state'

export default async function getOpenARLink (sku: string): Promise<string> {
  if (!state.init) return ''

  const customerId = state.customer._id

  const link = await get(`/sdk-ar/openar-link?customerId=${customerId}&productSku=${sku}`) as string
  return Promise.resolve(link)
}
